import Layout from '../containers/layout.js';
import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
//use effect
import { useEffect } from 'react';
import fetch from 'node-fetch';

// import searchTemplate css
import './styles/searchTemplate.css';

const slugify = require('slugify');
const nestUrls = require('../util/nestUrls');


const parsePageData = (obj,searchkeys) => {
  
  const returnArray = [];

  const iterate = (obj,searchkeys) => {

    Object.keys(obj).forEach(key => {

    if (typeof obj[key] === 'object' && obj[key] !== null) {
            iterate(obj[key],searchkeys)
    }else{
        if(searchkeys.indexOf(key)>-1){
          returnArray.push(obj[key]);
        }
    }
    })
    return returnArray;
  }
  
  return iterate(obj,searchkeys);
  
}



//create gatsby search page
const SearchPage =  ({ data, location }) => {


  //hook for the search box
  const [newData, setNewData] = React.useState([]); 

  const [displayAllType, setDisplayAllType] = React.useState('all'); 

  //toggle element display on click
  const [display, setDisplay] = React.useState({display:'none'});
  const toggleDisplay = () => {
    if(display.display === 'none'){
      setDisplay({display:'block'});
    }else{
      setDisplay({display:'none'});
    }
  }
  const url = `https://h1trleotw3.execute-api.us-east-1.amazonaws.com/`;
  const getData = async () => {
    const response = await fetch(url);
    const data = await response.json();

    let newDataCopy = [];
    
    //push data to newData
    for (let i in data){
      let product_item = {};
      for (let item of data[i].match(/<(.*?)>(.*?)<\/(.*?)>/g)){
        let key = item.substring(1,item.search(">"));
        let val = item.substring(item.search(">")+1,item.search("</"));
        product_item[key]=val;
      };
      newDataCopy.push(['/product/'+product_item.sku,data[i],product_item.desc,product_item.sku,`https://d1mx6xtulfr7us.cloudfront.net/${product_item.sku}/0_original_large.png`,'product']);
    }

    if(newData.length < 60){
      setNewData([...newData,...newDataCopy]);
    }
    

  }
  
  //get the data only on the first render
  getData();


  useEffect(() => {



  



  const pageUrlList = nestUrls(data.strapiPageHierarchy);

  let getUrl = () => '';
  
  let pageDataCopy = [];

  for(let key of Object.keys(data)){

    //if key is allstrapidatapages
    if(key === 'allStrapiPages'){
        getUrl = page => {
            const url = pageUrlList.find((el) => el.title === page.Title);
            const urlData = 
            url !== undefined
            ? `/${slugify(url.slug, {
                remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
                lower: true,
                })}`
            : `/${slugify(page.SEO.Slug, {
                remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
                lower: true,
                })}`;
          return urlData;
        };
    }
    //if key is allstrapiblogs
    else if(key === 'allStrapiBlogs'){
          getUrl = blog => blog.SEO.Slug  
      ? `/about/blog/${blog.SEO.Slug}`  
      : `/about/blog/${slugify(blog.Title, {
          remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
          lower: true,
      })}`;
    }
    //if key is allstrapiteammembers
    else if(key === 'allStrapiTeamMembers'){
          getUrl = person => `/about/team/${slugify(person.Name, {
          remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
          lower: true,
      })}`;
    }
    else { 
      continue;
    }

    //loop through each data key node
    //if node exists push to newData
    if(typeof data[key].nodes !== 'undefined'){
      for(let node in data[key].nodes){
        //push each node into newData
        //parse all text data out of json object
        //if there is a metadescription
        let description = '';
        let title = '';
        let thumbnail = key === 'allStrapiBlogs' && data[key].nodes[node].CoverImage ? data[key].nodes[node].CoverImage.formats.thumbnail.url : 
                        key === 'allStrapiTeamMembers' && data[key].nodes[node].Picture ? data[key].nodes[node].Picture.formats.thumbnail.url : 
                        key === 'allStrapiPages' && data[key].nodes[node].SEO.PreviewImage ? data[key].nodes[node].SEO.PreviewImage.formats.thumbnail.url : '';

        if(typeof data[key].nodes[node].HasBioPage !== 'undefined' && data[key].nodes[node].HasBioPage === false){
          continue;
        }

        if(typeof data[key].nodes[node].HasBioPage !== 'undefined' && data[key].nodes[node].HasBioPage === false){
          continue;
        }
        
        if(typeof data[key].nodes[node].SEO !== 'undefined' && data[key].nodes[node].SEO){
          if(typeof data[key].nodes[node].SEO.MetaDescription !== 'string'){
            continue;
          }
          description = data[key].nodes[node].SEO.MetaDescription||'';
          title = data[key].nodes[node].Name||data[key].nodes[node].Title||data[key].nodes[node].SEO.MetaTitle||'';
        }else{
          continue;
        }
        let url = getUrl(data[key].nodes[node]);

        //parsePageData to get Content, Title, Description, CTAs
        let parsedData = parsePageData(data[key].nodes[node],['Content','Title','Description','CTAs']).map((el)=>el||'').join(' ');

        //remove all urls
        parsedData = parsedData.replace(/(https?:\/\/[^\s]+)/g, '');

        //remove all urlencoded characters
        parsedData = parsedData.replace(/%[0-9A-F]{2}/g, '');

        // remove everything that is a code like nbsp;
        parsedData = parsedData.replace(/&[a-z]+;/g, '');

        //remove apstrophes
        parsedData = parsedData.replace(/'/g, '');

        //remove the word "undefined" all lowercase

        parsedData = parsedData.replace(/undefined/g, '');

        parsedData = parsedData.replace(/(\r\n|\n|\r|\t)/gm, ' ');
        //remove all non alphanumeric characters except spaces and apostrophes
        parsedData = parsedData.replace(/[^a-zA-Z0-9\.' ]/g, ' ');
        
        //push parsed data into newData
          pageDataCopy.push([url,title+' '+parsedData,JSON.stringify(data[key].nodes[node]),title,thumbnail,key]);
        

      }
    }
  }
  
  setNewData(pageDataCopy);
}, []);

  


  //hook for the search box
  const [searchTerm, setSearchTerm] = React.useState('');
  //set search term
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  //scan the json data for the search term

  //remove all html tags and non words from page[1]
  
  
  const results = !searchTerm
    ? []
    : newData.filter(page =>  {
       //if page[1] isn't a string with length > 1 then console log it and return false
        if(typeof page[1] !== 'string'){
         console.log(page[1]);
          return false;
        }

        const key = displayAllType === 'pages' ? 'allStrapiPages' : displayAllType === 'blogs' ? 'allStrapiBlogs' : displayAllType === 'team' ? 'allStrapiTeamMembers' : displayAllType === 'products' ? 'product' : 'all';

        if (displayAllType === 'all' || page[5] === key) {
          return page[1].toLowerCase().replace(/[^a-z0-9\s]/g,'').includes(searchTerm.toLowerCase().replace(/[^a-z0-9\s]/g,''));
        }else{
          return false;
        }
    }
      );

    //stylize results to jsx
    const searchResults = results.slice(0,100).map(page => {

      let displaypage = '';
      
      
      if (page[5] === 'product'){

        displaypage=page[2];

      }else{
       
        displaypage = page[1].replace(/V3/g,'Vthree').replace(/(\r\n|\n|\r)/gm, ' ').replace(/<(?:.|\n)*?>/gm, '').replace(/\d+/g, '').replace(/\w{20,}/g, '').replace(/Vthree/g,'V3').replace(/\s[A-Za-z]\s/g, ' ').replace(/\s[A-Za-z]*nbsp[A-Za-z]*\s/g, ' ').replace(/(\sn{2,})|(n{2,}\s)/g, '');
      
      }
      
      //jsx for searchterm highlighting
      const highlight = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

        //remove everything from part[0] before DOTPLACEHOLDER
        parts[0] = parts[0].replace(/[^.]*DOTPLACEHOLDER/,'');
        // the first part should be the last three words before the search term
        const firstPart = parts[0].split(' ').slice(-4).join(' ');
        //the second part should be the search term
        const secondPart = parts[1];
        //the third part should be the first three words after the search term
        //if parts[2] doesn't exist then set it to an empty string
        parts[2] = parts[2] ? parts[2] : '';
        let thirdPart = parts[2].split('DOTPLACEHOLDER')[0].split(' ').slice(0,20).join(' ')+'...';
        //if the third part is less than 3 words long then add the next part
        
        if((firstPart+secondPart+thirdPart).length < 20){
          return(
            <span></span>
          )
        }
        

        //return the jsx
        return (
          parts.length > 1?
          <span>
            {firstPart}<span style={{fontWeight:'bold', backgroundColor:'yellow'}} >{secondPart}</span>{thirdPart} 
          </span> 
          :null
        );
      }
      //return the jsx

      
        

      
      
      
      return (
      <li style={{borderBottom:'1px solid gray', listStyleType:'none', padding:'10px 5px', height:'120px', overflow:'hidden'}} key={page[0]}>
        {/* if thumbnail isn't blank include an image */}
        {/* create a div to hold the image whilst providing a constant width */}
        <div style={{width:'200px', height:'100px', float:'left', padding:'0px', overflow:'hidden', marginRight:'5px' }}>
        {page[4] !== '' && <img   
                                  src={page[4]} 
                                  alt={page[3]}
                                  style={{maxHeight:'100px'}}
                                  data-try="first-try"
                                  onError={({ currentTarget }) => {
                                    //try this only once
                                    if(currentTarget.getAttribute('data-try') === 'first-try'){
                                      currentTarget.onerror = null; 
                                      currentTarget.src=currentTarget.src.replace(".png",".jpg");
                                      currentTarget.setAttribute('data-try','second-try');
                                    }else if(currentTarget.getAttribute('data-try') === 'second-try'){
                                      currentTarget.onerror = null; 
                                      currentTarget.src="https://d1mx6xtulfr7us.cloudfront.net/coming-soon.png";
                                      currentTarget.setAttribute('data-try','not-found');
                                    }
                                  }}
                                  
                            />}
        </div>
        <Link to={page[0]} ><h1 style={{fontSize:'20px', marginBottom:'8px', lineHeight:'22px'}} >{page[3]}</h1></Link>
        {/* print highlighted search term within the text it was found */}
        {highlight(displaypage, searchTerm.replace(/[^a-z0-9\s]/g,''))}
        <div style={display} >{page[1]}</div>
        
       

      </li>
    )});
{/* lets create some nice fullwidth style for the search bar and include a magnifying glass on the right side */}
    const searchStyle = {
      width:'100%',
      height:'40px',
      border:'2px solid black',
      boxSizing:'border-box',
      position:'relative',
      padding:'5px',
      borderRadius:'0px !important',
    }
    // the search icon should be a black rectangle with the word search inside of it
    const searchIconStyle = {
      position:'absolute',
      right:'0px',
      top:'0px',
      padding:'14px',
      height:'38px',
      fontSize:'14px',
      fontWeight:'500',
      letterSpacing:'1px',
      backgroundColor:'black',
      color:'white',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      margin:'0px',
      cursor:'pointer',
    }
    const containerSearchStyle = {
      width:'100%',
      margin:'5px auto',
      // the search icon needs to be positioned relative to the search bar
      position:'relative',
      borderRadius:'0px !important',
      outline:'none'
    }
  
  const pageResults=()=>{
    
    



    const displayItem = (img,link,title,content,display) => (
      display?
      <li style={{borderBottom:'1px solid gray', listStyleType:'none', padding:'10px 5px', height:'120px', overflow:'hidden'}} key={title}>
        {/* if thumbnail isn't blank include an image */}
        {/* create a div to hold the image whilst providing a constant width */}
        <div style={{width:'200px', height:'100px', float:'left', padding:'0px', overflow:'hidden', marginRight:'5px' }}>
        {img !== '' && <img   
                                  src={img} 
                                  alt=''
                                  style={{maxHeight:'100px'}}
        
                         
                                  
                            />}
        </div>
        <Link to={link} ><h1 style={{fontSize:'20px', marginBottom:'8px', lineHeight:'22px'}} >{title}</h1></Link>
        {/* print highlighted search term within the text it was found */}

        <div >{content}</div>
        
       

      </li>:null
    );
    
    
    const getUrl = (page,key) => {
        //if key is allstrapidatapages
        let retFunc=()=>{};
        if(key === 'pages'){
          retFunc = page => {
              const url = nestUrls(data.strapiPageHierarchy).find((el) => el.title === page.Title);
              const urlData = 
              url !== undefined
              ? `/${slugify(url.slug, {
                  remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
                  lower: true,
                  })}`
              : `/${slugify(page.SEO.Slug, {
                  remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
                  lower: true,
                  })}`;
            return urlData;
          };
      }
      //if key is allstrapiblogs
      else if(key === 'blogs'){
        retFunc = blog => blog.SEO.Slug  
        ? `/about/blog/${blog.SEO.Slug}`  
        : `/about/blog/${slugify(blog.Title, {
            remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
            lower: true,
        })}`;
      }
      //if key is allstrapiteammembers
      else if(key === 'team'){
        retFunc = person => `/about/team/${slugify(person.Name, {
            remove: /[*+~.,()'"!?'{}#$%^&`|<>=:@]/g,
            lower: true,
        })}`;
      }
      return retFunc(page);
    }
    

    switch(displayAllType){
      case 'pages':
        return data.allStrapiPages.nodes.sort((a,b)=>a.Title < b.Title ? -1 : a.Title > b.Title ? 1 : 0).map(item=>(displayItem(item.SEO.PreviewImage ? item.SEO.PreviewImage.formats.thumbnail.url : '',getUrl(item,'pages'),item.Title,'',true)));
      case 'blogs':
        return data.allStrapiBlogs.nodes.sort((a,b)=>a.Title < b.Title ? -1 : a.Title > b.Title ? 1 : 0).map(item=>(displayItem(item.CoverImage ? item.CoverImage.formats.thumbnail.url : '',getUrl(item,'blogs'),item.Title,'',true)));
      case 'team':
        return data.allStrapiTeamMembers.nodes.sort((a,b)=>a.Title < b.Title ? -1 : a.Title > b.Title ? 1 : 0).map(item=>(displayItem(item.Picture ? item.Picture.formats.thumbnail.url : '',getUrl(item,'team'),item.Name,item.Title,item.HasBioPage)));
      default:
        return <></>;
    }

  }

  // const pageResults=['yello','fello'].map(item=>(<span key={item}>{item}</span>));

  // const pageResults  = JSON.stringify(data.allStrapiPages.nodes);

  return (
    <Layout location={location}>
      <div className="search-page" style={{maxWidth:'1000px', margin:'auto'}} >
        <h3>V3 Search</h3>
        {/* search */}
        {/* create an input that uses the searchStyle and searchIconStyle */}
        {/* container div so the search icon shows up in the input box */}
        <div style={containerSearchStyle}>
          {/* dangerously set inner html of style for input focus outline none */}
          
        <input type="text" className="searchInput" style={searchStyle} onChange={handleChange} value={searchTerm} placeholder="Search..." />
        <div style={searchIconStyle}>Search</div>
        </div>
        {/* if there are results print them */}

        {/* create spans called pages blogs and team that when clicked will set displayAllType to their respective names */}
        <span style={{cursor:'pointer',fontWeight:(displayAllType==='all'?'bold':'')}} onClick={()=>setDisplayAllType('all')} > all </span> | 
        <span style={{cursor:'pointer',fontWeight:(displayAllType==='pages'?'bold':'')}} onClick={()=>setDisplayAllType('pages')} > pages </span> | 
        <span style={{cursor:'pointer',fontWeight:(displayAllType==='blogs'?'bold':'')}} onClick={()=>setDisplayAllType('blogs')} > blogs </span> | 
        <span style={{cursor:'pointer',fontWeight:(displayAllType==='team'?'bold':'')}} onClick={()=>setDisplayAllType('team')} > team </span> | 
        <span style={{cursor:'pointer',fontWeight:(displayAllType==='products'?'bold':'')}} onClick={()=>setDisplayAllType('products')} > products </span>


        <ul id="results">
          {searchTerm.length>0?searchResults:pageResults()}
        </ul>
      </div>
    </Layout>
  );
};

  //query site data for search
  export const query = graphql`
  query SearchQuery {
    allStrapiPages {
      nodes {
        id
        Title
        Template
        Content
        Other
        SEO {
          Slug
          MetaTitle
          MetaDescription
          Keywords
          PreviewImage {
            formats {
              thumbnail {
                url
              }
            }
          }
        }
        HasCustomHTML
        CustomHTML {
          HTML
          Style
        }
        Header {
          CTA
          CTAButtonText
          Content
          Title
        }
      }
    }
    allStrapiTeamMembers {
      nodes {
        id
        Name
        Email
        Title
        PhoneNo
        Content
        HasBioPage
        MeetingLink
        SEO {
          MetaTitle
          MetaDescription
          Keywords
        }
        Picture {
          formats {
            thumbnail {
              url
            }
          }
        }
      }
    }
    allStrapiBlogs {
      nodes {
        id
        Author
        Title
        Date
        Tags
        CoverImage {
          url
          formats {
            thumbnail {
              url
            }
          }
        }
        Content
        SEO {
          MetaTitle
          MetaDescription
          Slug
          Keywords
          PreviewImage {
            url
          }
        }
      }
    }
    allStrapiRedirects {
      nodes {
        id
        From
        To
        Type
      }
    }
    strapiPageHierarchy {
      Pages {
        Page {
          PageData {
            Title
            SEO {
              Slug
            }
          }
        }
        Children {
          ChildPageData {
            Title
            SEO {
              Slug
            }
          }
        }
      }
      Subpages {
        Page {
          PageData {
            Title
            SEO {
              Slug
            }
          }
        }
        Children {
          ChildPageData {
            Title
            SEO {
              Slug
            }
          }
        }
      }
    }
  }
  `;

export default SearchPage;
